<template>
    <div class="p-relative">
        ЖУРНАЛЫ
    </div>
</template>

<script>

export default {

    data() {
        return {
        }
    },
    computed: {
    },
    methods: {
    },
    watch: {
    },
}
</script>
