<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{$t('ВНИМАНИЕ!!!')}}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>
                            {{ $t('При_смене_типа_используемой_на_сервере_ЭЦП, все ранее загруженные пользователями сертификаты открытых ключей ЭЦП и так же параметры этих ключей будут безвозвратно уничтожены. Вы уверены что хотите сменить тип используемой на сервере ЭЦП') }}
                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn 
                        color="cyan" 
                        text 
                        depressed 
                        @click="save" 
                        >
                        {{$t("Подтверждаю")}}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>

export default {
    name: "ConfirmChangeSignTypeDlg",
    data () {
        return {
            visible: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
        }
    },
    methods: {
        async open()
        {
            this.visible = true;
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        save() {
            this.visible = false;
            this.resolve();
        },
        cancel() {
            this.visible = false;
            this.reject();
        }
    }
}
</script>