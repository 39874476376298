<template>
    <div v-if="dataSource" class="p-relative">

        <Toolbar :isList="true" :hideCreateButton="hideCreate" :menu="dataSource.Data.Menu" v-on:toolbar-button-click="onToolbarClick">

            <div class="top-btns-bar-right-section">

                <v-text-field
                    v-model="search"
                    append-icon="fas fa-search"
                    label="Что ищем? Например: краткое содержание"
                    hide-details
                    class="search-in-tooltip"
                    solo
                    flat
                    clearable
                ></v-text-field>
            </div>

        </Toolbar>

        <FilterPanel
            v-if="filterDataSource"
            :dataSource="filterDataSource"
            v-bind:status.sync="collection"
        />

        <component :is="collection" :search="search" ref="childComponent"/>
        
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Axios from 'axios';
import { httpAPI } from "@/api/httpAPI";
import Toolbar from '@/components/Toolbar.vue';
import FilterPanel from '@/components/FilterPanel.vue';
import Logs from './lists/Logs.vue';
import Configs from './cards/ConfigureServer.vue';

export default {
    name: "Security",
    components: {
        Toolbar,
        FilterPanel,
        Logs,
        Configs
    },
    data() {
        return {
            cancellationTokenSorce: null,
            dataSource: null,
            filterDataSource: null,
            //refreshConnectedUserInterval: null
        }
    },
    computed: {
        ...mapGetters('auth', ['getUserInfo']),
        collection: {
            get: function() {
                return this.$store.getters['security/getCollection']
            },
            set: function(newValue) {
                this.$store.commit('security/SET_COLLECTION', newValue);
            }
        },
        search: {
            get: function() {
                return this.$store.getters['security/getSearch']
            },
            set: _.debounce(function(v) {
                this.$store.commit('security/PUSH_SEARCH', { collection: this.collection, value: v } );
            }, 500)
        },
        hideCreate() {
            return this.getUserInfo.levelAccess != 1;
        }
    },
    methods: {
        ...mapActions(['setOverlayVisible']),
        async onToolbarClick (event, btn) {
            switch(btn.Action)
            {
                case 'Refresh':
                    await this.loadFilters();
                    await this.$refs.childComponent.getData(true);
                    break;

                default:
                    await this.$refs.childComponent.onToolbarClick(event, btn);
                    break;        
            }
            
        },
        async loadCollectionData(type) {
            let response = await httpAPI({
                url: `api/security/collection?type=`+ (type ? type: this.collection),
                method: 'GET'
            });

            if (response) {
                this.dataSource = response.data.payload;
            }
        },
        async loadFilters() {
            //let userInfo = this.$store.getters['auth/getUserInfo'];

            let filterResponse = await httpAPI({
                url: `api/security/filter?level=${this.getUserInfo.levelAccess}`,
                method: 'GET',
                cancelToken: this.cancellationTokenSorce.token,
            });
            this.filterDataSource = filterResponse?.data.payload;
        }
    },
    watch: {
        collection()
        {
            this.loadCollectionData();
        }
    },
    async created() {
        this.setOverlayVisible({ visible: true });

        if (this.cancellationTokenSorce)
            this.cancellationTokenSorce.cancel('Обновление предыдущей категории прервано из-за выбора новой категории');

        this.cancellationTokenSorce = Axios.CancelToken.source();
        
        await this.loadFilters();
        await this.loadCollectionData();

        this.setOverlayVisible({ visible: false });
        //this.refreshConnectedUserInterval=setInterval(this.loadFilters, 5000);
    },
    beforeDestroy() {
        //clearInterval(this.refreshConnectedUserInterval);
    }
}
</script>