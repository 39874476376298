<template>
    <div class="p-relative">
        
        <v-form class="wrapperForm" ref="form" lazy-validation>
            <v-row dense>

                <!--Обертка для секции-->
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">{{ $t("Параметры_контроля_активности_пользователя") }}</div>

                            <v-row 
                                v-for="item in serverconfigrows"
                                :key="item.fieldname"
                                class="full-width-row" 
                                no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <v-row no-gutters>
                                        <label class="f-label">
                                            {{item.code}}
                                        </label>
                                        <v-icon small v-tooltip.left-center="item.description">
                                            far fa-question-circle
                                        </v-icon>
                                    </v-row>    
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field
                                        v-model="item.value"
                                        hide-details
                                        outlined
                                        dense
                                        type="number"
                                        :rules="[checkValue(item, item.value)]"
                                        required
                                        class="cust-inputnumber"
                                        @input="updateSecurityValue"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">{{ $t("Тип_используемой_ЭЦП") }}</div>
                            <v-row 
                                class="full-width-row" 
                                no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <v-row no-gutters>
                                        <label class="f-label">
                                            {{ $t("Тип_используемой_ЭЦП") }}
                                        </label>
                                    </v-row>    
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-select
                                        :value="signTypeSelectedValue"
                                        :items="signTypes"
                                        item-text="name" 
                                        item-value="id"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                        :menu-props="{ bottom: true, offsetY: true }"
                                        @input="updateTypeSign($event)"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="12" md="6" class="section-wrapper">
                    <v-card flat>
                        <v-card-text>
                            <div class="form-box-title">{{ $t("Требования_к_паролю_пользователя") }}</div>

                            <v-row 
                                v-for="item in passwordcofigrows"
                                :key="item.fieldname"
                                class="full-width-row" 
                                no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <v-row no-gutters>
                                        <label class="f-label">
                                            {{item.code}}
                                        </label>
                                        <v-icon small v-tooltip.left-center="item.description">
                                            far fa-question-circle
                                        </v-icon>
                                    </v-row>    
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-text-field
                                        v-model="item.value"
                                        hide-details
                                        outlined
                                        dense
                                        type="number"
                                        :rules="[checkValue(item, item.value)]"
                                        required
                                        class="cust-inputnumber"
                                        @input="updateSecurityValue"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>

        <ConfirmChangeSignTypeDlg ref="ConfirmChangeSignTypeDlgRef" />
    </div>
</template>


<script>
//import Toolbar from '@/components/Toolbar.vue';
import { mapActions } from 'vuex';
import { httpAPI } from "@/api/httpAPI";
import i18n from '@/i18n';
import ConfirmChangeSignTypeDlg from '@/components/dialogs/ConfirmChangeSignTypeDlg.vue'

export default {
    name: "ConfigureServerCard",
    components: {
        ConfirmChangeSignTypeDlg
    },
    data() {
        return {
            configrows: [],
            loadedConfig: [],
            signTypeSelectedValue: 1,
            signTypes: [
                {id: 1, name: i18n.t('УЦГО') },
                {id: 2, name: i18n.t('НУЦ') },
            ]
        }
    },
    computed:{
        /*isViewMode() {
            return this.dataSource?.FormId === 602021;
        },*/
        serverconfigrows() { return this.configrows.filter(item=>item.section == 'Server' && item.code != 'USED_SIGN_TYPE') },
        passwordcofigrows() { return this.configrows.filter(item=>item.section == 'Password') },
        
    },
    methods: {
        ...mapActions('toolbar', ['setButtonLock', 'setButtonUnLock']),
        async getData (){
            await this.getSettingsListe();
        },
        async onToolbarClick(e, btn) {
            await this[btn.Action]();
        },
        async getSettingsListe(){
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url = `/api/security/getsecuritysettingslist`;  
            let response = await httpAPI({
                url,
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response){ 
                this.loadedConfig = []; 
                this.configrows = response.data.payload;
                this.configrows.forEach((item) => {
                    this.loadedConfig.push({code: item.code, value: item.value});
                });
                this.signTypeSelectedValue = this.configrows.find(item=>item.code == 'USED_SIGN_TYPE')?.value ?? 1;
                this.setButtonLock('SaveConfigs');
            }
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
        },
        getValue(code){
            let dependConf = this.configrows.find(configitem => configitem.code==code);
            if (dependConf)
                return dependConf.value;
            else 
                return 0;
        },
        checkValue(item, v){
            if (item.valueDependFrom){
                let checkvalue = this.getValue(item.valueDependFrom);
                if (checkvalue){
                    if (!isNaN(parseInt(v)) && ((item.allowZeroValue && v==0) || (v >= item.minValue && v<=item.maxValue))) 
                        return true;
                    else 
                        return item.allowText;
                }
                else {
                    if (!isNaN(parseInt(v)) && v==0) 
                        return true;
                    else 
                        return item.allowText;
                }
            }
            let min = item.minValue;
            let max = item.maxValue;
            if (item.minValueDependFrom)
                min = this.getValue(item.minValueDependFrom);
            if (item.maxValueDependFrom){
                max = this.getValue(item.maxValueDependFrom);
            }
            if (!isNaN(parseInt(v)) && ((item.allowZeroValue && v==0) || (v >= min && v<=max))) 
                return true; 
            return item.allowText;
        },
        async updateTypeSign(event){
            let r = this.configrows.find(item=>item.code == 'USED_SIGN_TYPE');
            if (r){
                this.signTypeSelectedValue = event;
                r.value = event;
            }
            this.updateSecurityValue();
        },
        async updateSecurityValue(){
            let updated = false;
            this.configrows.forEach((row) => {
                let r = this.loadedConfig.find(item=>item.code==row.code);
                if (r && r.value != row.value){
                    updated = true;
                }
                /*row.check = this.checkValue(row, row.value);
                if (typeof(row.check) != "boolean")
                    console.log(row.code, row.value, row.check);*/
            });

            if (updated){
                this.setButtonUnLock('SaveConfigs');
            }
            else {
                this.setButtonLock('SaveConfigs');
            }
        },
        //#region Действия (тулбар)
        async SaveConfigs() {
            let validateResult = this.$refs.form.validate();
            if (!validateResult) {
                this.$notify.alert(this.$t("Имеются не корректные значения"));
                return;
            }
            let signTypeLoadedValue = this.loadedConfig.find(item=>item.code == "USED_SIGN_TYPE")?.value ?? 1;
            if (signTypeLoadedValue != this.signTypeSelectedValue){
                try {
                     await this.$refs.ConfirmChangeSignTypeDlgRef.open();
                }
                catch {
                    return;
                }
            }
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: true });
            let url = `/api/security/updatesecuritysettings`;  
            let content = { Content : JSON.stringify({ list: this.configrows.map(item=>{return {code:item.code, value:item.value}})}) };
            let response = await httpAPI({
                url,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: content
            });
            if (response) {
                this.$notify.success(response.data.payload.Message);
                this.loadedConfig = []; 
                this.configrows.forEach((item) => {
                    this.loadedConfig.push({code: item.code, value: item.value});
                });
                this.setButtonLock('SaveConfigs');
            }
            this.$store.commit({ type: 'SET_OVERLAY_VISIBLE', visible: false });
        },
        //#endregion
    },
    created() {
        (async() => {
            await this.getSettingsListe();
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.validate();
        })();      
    },
    updated() {
        this.$refs?.form?.validate();
    }
}
</script>